



























import {Component, Vue} from "vue-property-decorator";

@Component
export default class Countdown extends Vue{
  private days = 0;
  private hours = 0;
  private minutes: number | string = 0;
  private seconds: number | string = 0;
  private countDownDate: number = new Date("June 10, 2023 19:00:00").getTime();
  private interval: any = undefined;

  mounted () {
      this.interval = setInterval(() => {
          this.setTime();
      });
  }

  beforeDestroy () {
      clearInterval(this.interval);
  }

  private setTime () {
      // Get today's date and time
      const now: number = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = this.countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = this.checkSingleDigit(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
      this.seconds = this.checkSingleDigit(Math.floor((distance % (1000 * 60)) / 1000));


      // If the count down is finished, write some text
      if (distance < 0) {
          clearInterval(this.interval);
      }
  }
  private checkSingleDigit (digit: any) {
      return ("0" + digit).slice(-2);
  }
}
