









































































import {Component, Vue, Watch} from "vue-property-decorator";
import HomeView from "@/components/partials/HomeView.vue";
import Infos from "@/components/partials/Landing/Infos.vue";
import Timetable from "@/components/partials/Landing/Timetable.vue";
import Imprint from "@/components/partials/Imprint.vue";
import Modal from "@/components/common/Modal/Modal.vue";

interface Location {
  id: number,
  title: string,
  text: string,
  address: string,
  link?: string,
  param: string
}

@Component({
    components: {
        HomeView, Infos, Timetable, Imprint, Modal
    }
})
export default class OpenAir extends Vue {
    private locations = require("../assets/data/locations.json");
    private events = require("../assets/data/events.json");
    private acts = require("../assets/data/acts.json");
    private showModal: boolean = false;
    private param: string = "";
    private selectedLocation = undefined;
    private modalData = {
        title: ""
    };

    created() {
        document.title = "Weimarer Kneipen-Kultour | 10.06.2023 ab 19uhr";
        this.setModal(this.$route.params.param);
    }

    @Watch("$route.params")
    paramsChanged(newParams: { [key: string]: string }) {
        this.setModal(newParams.param);
    }

    private locationEvents(locationId: number) {
        return this.events.filter((event: any) => event.location === locationId).sort((a: any,b: any) => {
            if (a.start[0] < b.start[0]) return -1;
            if (a.start[0] > b.start[0]) return 1;
            return 0;
        });
    }

    private setModal(param: string): void {
        if(!param) {
            this.showModal = false;
            return;
        }
        const location = this.locations.find((location: Location) => param === location.param);
        if(location) {
            this.modalData.title = location.title.toLowerCase().includes("bar") ? "Willkommen in der " + location.title : "Willkommen im " + location.title;
            this.selectedLocation = location;
            // this.modalData.body = location.text;

        } else if(param === "deckel") {
            // TODO
        }
        else return;
        this.showModal = true;
    }
}
