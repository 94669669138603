














































import {Component, Vue} from "vue-property-decorator";

interface Event {
  id: number,
  title?: string,
  type: string,
  act?: number,
  location: number,
  start: Array<string>,
  end?: Array<string>
}

@Component
export default class Timetable extends Vue {
    private locations = require("../../../assets/data/locations.json");
    private events = require("../../../assets/data/events.json");
    private acts = require("../../../assets/data/acts.json");
    private leftWidth = 0;
    private cellWidth = 0;

    protected getTime(hoursToAdd: number) {
        const date = new Date("2023-06-10 18:00");
        date.setHours(date.getHours() + hoursToAdd, 0, 0);
        return date.getHours();
    }
    protected getHourDiff(time: string, diffTime: string) {
        const reg = new RegExp(/^\d{2}:\d{2}/);
        if(reg.test(time)) time = "2023-06-10T" + time;
        if(reg.test(diffTime)) diffTime = "2023-06-10T" + diffTime;
        const date = Date.parse(time);
        const diffDate = Date.parse(diffTime);
        return Math.abs(date - diffDate) / 3600000;
    }
    protected getEventWidth(event: Event, index: number) {
        if(event.end && event.end[index]) return this.cellWidth * this.getHourDiff(event.start[index], event.end[index]) - 5 + "px";
        else return this.cellWidth * this.getHourDiff(event.start[index], "2023-06-11T02:00") + "px";
    }

    getEventsForLocation(id: number) {
        const events = this.events.filter((event: Event) => id === event.location);
        events.map((event: Event) => {
            if(event.type === "live") {
                const act = this.acts.find((act: any) => event.act === act.id);
                event.title = "<b>" + act.name + "</b><i>" + act.genre + "</i>";
            }
            return event;
        });
        events.sort((a: Event,b: Event) => {
            if (a.start[0] < b.start[0]) return -1;
            if (a.start[0] > b.start[0]) return 1;
            return 0;
        });
        return events;
    }

    mounted() {
        console.log(document.documentElement.clientHeight);
        console.log(this.$refs);
        this.cellWidth = (this.$refs.cell as Array<HTMLDivElement>)[0]?.clientWidth - 1;
        this.leftWidth = (this.$refs.location as HTMLDivElement)?.offsetWidth; //document.documentElement.clientHeight > 920 ? (this.$refs.location as HTMLDivElement)?.offsetWidth : 0;
        console.log("event", this.$refs.eventinfo);

        // (this.$refs.timetable as HTMLDivElement).onscroll = (event: any) => {
        //     (this.$refs.eventinfo as Array<HTMLDivElement>).forEach((element: any) => {
        //         element.style.textIndent = event.target.scrollLeft + "px";
        //     });
        // };
    }
}
