






















import {Component, Vue} from "vue-property-decorator";
import MobileMenuButton from "./components/common/MobileMenuButton.vue";

@Component({
    components: {
        MobileMenuButton
    }
})
export default class App extends Vue {
  private navOpen: boolean = false;

  get view() {
      return this.$store.getters.getView;
  }

  private scrollTo(to: string) {
      this.$scrollTo(to);
      this.navOpen = false;
  }
}
