














































import { Component, Vue } from "vue-property-decorator";
import Countdown from "@/components/Countdown.vue"; // @ is an alias to /src
import Scrolldown from "@/components/common/Scrolldown.vue"; // @ is an alias to /src

interface Location {
  logo: string;
  name: string;
  address: string;
}

@Component({
    components: {
        Countdown, Scrolldown
    }
})
export default class HomeView extends Vue {
  private locations = require("../../assets/data/locations.json");

  openLink(url: string) {
      if(!url) return;
      window.open(url, "_blank");
  }
}
