import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export interface DefaultState {
    view: string;
}

export default new Vuex.Store({
    state: {
        view: "default"
    },
    getters: {
        getView(state: DefaultState) {
            return state.view;
        }
    },
    mutations: {
        SET_VIEW(state: DefaultState, view: string) {
            state.view = view;
        }
    },
    actions: {
    },
    modules: {
    }
});
