








import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class MobileMenuButton extends Vue {
  @Prop({type: Boolean, required: true}) open!: boolean;
}
