import Vue from "vue";
import VueRouter, {NavigationGuardNext, Route, RouteConfig} from "vue-router";
import store from "../store";
import Landing from "@/views/Landing.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "home",
        component: Landing
    },
    {
        path: "/:param",
        name: "detail",
        component: Landing
    }
    // {
    //     path: "/imprint",
    //     name: "imprint",
    //     component: Landing
    // }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

// router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
//     //Sub-domain routing
//     const host = window.location.host.split(".");
//
//     if(host.length >= 3 && host[0] === "flohmarkt") {
//         store.commit("SET_VIEW", "fleamarket");
//     }else {
//         store.commit("SET_VIEW", "default");
//     }
//
//     next();
// });

export default router;
